<template>
  <div class="row" data-app>
    <div
      v-if="!isLoading"
      class="col-12">
      <div class="card">
        <div class="card-header">
          <strong>Settings & Configuration</strong>
        </div>
        <div class="card-body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus dicta dolorem id neque odio repellat
            saepe vitae voluptatum. Ex facere fuga nemo perspiciatis ullam. Cupiditate delectus impedit libero neque
            sit.
          </p>
          <hr>
          <v-data-table
            :headers="headers"
            :items="settings"
            :items-per-page="15"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <div class="row">
                <div class="col-6">
                  <div
                    @click="addAttribute"
                    class="btn btn-secondary btn-sm">
                    <i class="fas fa-plus"> </i> Add Attribute
                  </div>
                </div>
                <div class="col-6">
                  <input type="text" v-model="search" class="form-control" placeholder="search">
                </div>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <SettingItemComponent
                :value="item"
                @remove="handleRemove"
                @saved="handleSaved"
              />
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <LoadingComponent/>
    </div>
  </div>
</template>
<script>

import LoadingComponent from '@/components/misc/LoadingComponent'
import SettingsServices from '@/service/SettingsServices'
import SettingItemComponent from '@/views/settings/SettingItemsComponent'

const defaultObject = () => {
  return {
    createdTime: new Date(),
    updatedTime: new Date(),
    keyid: '',
    value: '',
    isEditable: true
  }
}
export default {
  name: 'SettingsScreen',
  components: { SettingItemComponent, LoadingComponent },
  data () {
    return {
      search: '',
      isLoading: true,
      settings: [],
      headers: [
        {
          text: 'Key',
          align: 'start',
          sortable: true,
          value: 'key'
        },
        { text: 'Value', value: 'value' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    async getCurrentSettings () {
      this.isLoading = true
      try {
        const res = await SettingsServices.getAllSettings({})
        if (res) {
          this.settings = [...this.settings, ...res.data]
          this.settings.map((v, k) => {
            v.index = k + 1
          })
        }
      } catch (e) {
        console.log(e)
      }

      this.isLoading = false
    },
    handleSaved (data) {
      this.settings.map((v) => {
        if (v.id === data.id) {
          v = data
          v.isEditable = false
        }
        v.isEditable = false
        return v
      })
    },
    addAttribute () {
      const data = {
        ...defaultObject(),
        index: this.settings.length + 1
      }
      this.settings.unshift(data)
    },
    handleRemove (e) {
      this.settings = this.settings.filter((v) => {
        return v.index !== e
      })
    }
  },
  mounted () {
    this.getCurrentSettings()
  }
}
</script>
<style lang="scss" scoped>
.card {
  text-align: left;
}
</style>
