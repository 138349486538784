<template>
  <tr>
    <td>
      <input
        v-if="isEditable"
        type="text"
        v-model="data.keyid"
        placeholder="Key"
        class="form-control" required>
      <span v-else>
        {{ data.keyid }}
      </span>
    </td>
    <td>
      <input
        v-if="isEditable"
        type="text"
        placeholder="Value"
        class="form-control" v-model="data.value" required>
      <span v-else>
        {{ data.value }}
      </span>
    </td>
    <td v-if="isLoading">
      <LoadingComponent/>
    </td>
    <td v-else>
      <div
        v-tooltip="'Save'"
        v-if="!data.id || isEditable"
        @click="save"
        class="btn btn-sm btn-primary mr-3">
        <i
          class="fas fa-save"></i>
      </div>
      <div
        v-tooltip="'Edit'"
        @click="editData"
        v-else class="btn btn-sm btn-secondary mr-3">
        <i class="fas fa-edit"></i>
      </div>
      <div
        v-tooltip="'Delete'"
        v-if="!isEditable"
        @click="removeElement"
        class="btn btn-danger btn-sm">
        <i class="fas fa-trash"></i>
      </div>
      <div
        v-tooltip="'Cancel'"
        @click="cancelEdit"
        v-else class="btn btn-danger btn-sm">
        <i class="fas fa-times"></i>
      </div>
    </td>
  </tr>
</template>
<script>

import LoadingComponent from '@/components/misc/LoadingComponent'
import SettingsServices from '@/service/SettingsServices'

const defaultObject = () => {
  return {
    createdTime: new Date(),
    updatedTime: new Date(),
    keyid: '',
    value: ''
  }
}

export default {
  name: 'SettingItemComponent',
  components: { LoadingComponent },
  props: {
    value: {
      type: Object,
      default: defaultObject(),
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      data: defaultObject()
    }
  },
  watch: {
    value (val) {
      this.data = val
    }
  },
  computed: {
    isEditable () {
      return this.data.isEditable
    }
  },
  methods: {
    cancelEdit () {
      console.log('cancel edit')
      this.data = { ...this.data, ...{ isEditable: false } }
    },
    editData () {
      this.data = { ...this.data, ...{ isEditable: true } }
    },
    async removeElement () {
      this.isLoading = true
      try {
        await SettingsServices.delete(this.data.id)
      } catch (e) {
        console.log('error delete', e, e.response)
      }
      await this.$emit('remove', this.data.index)
      this.isLoading = false
    },
    async save () {
      this.isLoading = true
      try {
        const params = {
          keyid: this.data.keyid,
          value: this.data.value,
          createdTime: this.data.createdTime,
          updatedTime: this.data.updatedTime
        }
        let res = null
        if (!this.data.id) {
          res = await SettingsServices.save(params)
        } else {
          params.updatedTime = new Date()
          res = await SettingsServices.update(params, this.data.id)
        }

        if (res && res.data) {
          this.data = { ...this.data, ...res.data, ...{ isEditable: false } }
          this.$emit('saved', this.data)
        }
      } catch (e) {
        console.log('error', e, e.response)
      }
      this.isLoading = false
    }
  },
  mounted () {
    if (this.value) this.data = this.value
  }
}
</script>
<style lang="scss" scoped></style>
